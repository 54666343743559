const darkBlue = {
    "dark-text-color": "#fff",
    "dark-scrollbar-color": "#02020c",
    "dark-background-color": "#101320",
    "dark-border-color": "#d42379",
    "dark-modal-background": "#101625f0",
    "dark-input-color": "#4c4c70",
    "dark-card-color": "#b4b4b4",
    "dark-card-shadow": "rgba(0, 0, 0, 0.5)",
    "dark-gradient-1": "#d53369",
    "dark-gradient-2": "#daae51",
    "dark-table-head": "rgba(0, 0, 0, 0.2)",
    "dark-table-hover": "rgba(50, 0, 200, 0.4)",
    "dark-neon-color-1": "#4D96FF",
    "dark-neon-color-2": "#FFD93D",
    "dark-pricing-color-1": "#FF8AAE",
    "dark-pricing-color-2": "#6BCB77",
    "dark-pricing-color-1-tr": "#FF8AAE20",
    "dark-pricing-color-2-tr": "#6BCB7720",
    "dark-candle-up": "#6BCB77",
    "dark-candle-down": "#ff009f",
    "dark-separator": "#f6f5e7",
    "dark-background-histogram": "#9090ff40",
    "dark-background-histogram2": "#60ff9050",
    "dark-background-histogram3": "#ffa00050",
}

export default darkBlue
