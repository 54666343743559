const dark = {
    "dark-text-color": "#ffffff",
    "dark-scrollbar-color": "#333333",
    "dark-background-color": "#101010",
    "dark-border-color": "#333333",
    "dark-modal-background": "#151515f0",
    "dark-input-color": "#333333",
    "dark-card-color": "#212121",
    "dark-card-shadow": "rgba(0, 0, 0, 0.5)",
    "dark-gradient-1": "#ff6b6b",
    "dark-gradient-2": "#f06595",
    "dark-table-head": "#212121",
    "dark-table-hover": "rgba(255, 107, 107, 0.4)",
    "dark-neon-color-1": "#01cdfe",
    "dark-neon-color-2": "#f5b700",
    "dark-pricing-color-1": "#ff3d3d",
    "dark-pricing-color-2": "#07f49e",
    "dark-pricing-color-1-tr": "#ff3d3d20",
    "dark-pricing-color-2-tr": "#07f49e20",
    "dark-candle-up": "#07f49e",
    "dark-candle-down": "#ff3d3d",
    "dark-separator": "#f6f5e7",
    "dark-background-histogram": "#ff6b6b80",
    "dark-background-histogram2": "#07f49e80",
    "dark-background-histogram3": "#f5b70080",
}

export default dark
