const purple = {
  "dark-text-color": "#fff",
  "dark-scrollbar-color": "#071023",
  "dark-background-color": "#120e18",
  "dark-border-color": "#333744",
  "dark-modal-background": "#1b1524",
  "dark-input-color": "#4c4c70",
  "dark-card-color": "#3c3f51",
  "dark-card-shadow": "rgba(20, 20, 40, 0.2)",
  "dark-gradient-1": "#8a60b0",
  "dark-gradient-2": "#6c3499",
  "dark-table-head": "rgba(32, 32, 64, 0.3)",
  "dark-table-hover": "rgba(32, 32, 64, 0.2)",
  "dark-neon-color-1": "#bb88dd",
  "dark-neon-color-2": "#ffcc00",
  "dark-pricing-color-1": "#ff66b2",
  "dark-pricing-color-2": "#6BCB77",
  "dark-pricing-color-1-tr": "#ff66b220",
  "dark-pricing-color-2-tr": "#6BCB7720",
  "dark-candle-up": "#6BCB77",
  "dark-candle-down": "#ff3385",
  "dark-separator": "#505060",
  "dark-background-histogram": "#9988dd40",
  "dark-background-histogram2": "#60ff9050",
  "dark-background-histogram3": "#ff800050",
}

export default purple
