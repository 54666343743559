import { withAuthenticator } from '@aws-amplify/ui-react'
import React from 'react'

function Login() {
    return (
        <></>
    )
}

export default withAuthenticator(Login)
