import React, { useState, useCallback, useContext, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import axios from "axios"
import { useDropzone } from "react-dropzone"

import styles from "./bulk.module.css"
import { Model } from "../../config"
import { Cell, Grid } from "styled-css-grid"
import SyntaxHighlighter from "react-syntax-highlighter"
import { shadesOfPurple } from "react-syntax-highlighter/dist/esm/styles/hljs"
import { Button, Content } from "react-bulma-components"
import { Settings, buildInitialState } from "../settings/settings"
import { withAuthenticator } from "@aws-amplify/ui-react"
import AWS from "aws-sdk"
import { SupportContentContext } from "../../support/support"
import banners from "../../data/banners.json"
import { generateName } from "../../utils"
import { createJob } from "../../data/job"

interface BulkProps {
    model: Model
}

const getRandomImage = () => {
    const randomIndex = Math.floor(Math.random() * banners.length)
    return banners[randomIndex]
}

const generateS3BucketUrl = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = `0${date.getMonth() + 1}`.slice(-2)
    const day = `0${date.getDate()}`.slice(-2)
    const randomUUID = uuidv4()
    return `year=${year}/month=${month}/day=${day}/${randomUUID}/`
}

function toTitleCase(input: string): string {
    return input
        .split("_") // Split by underscore
        .map(
            part =>
                part
                    .toLowerCase() // Convert to lower case
                    .replace(/^\w/, c => c.toUpperCase()) // Capitalize the first letter
        )
        .join(" ") // Join the parts with spaces
}

const Bulk: React.FC<BulkProps> = ({ model }) => {
    const [backgroundImage, setBackgroundImage] = useState("")

    const [files, setFiles] = useState<File[]>([])
    const [s3BucketUrl, setS3BucketUrl] = useState<string>(generateS3BucketUrl())
    const [settingsVisible, setSettingsVisibile] = useState(false)
    const [customModel, setCustomModel] = useState(model.model_name)
    const isModelCustom = model.model_name === null
    const [customNotification, setCustomNotification] = useState("")

    const [progress, setProgress] = useState(0)
    const [progressBarVisible, setProgressBarVisible] = useState(false)
    const [deployed, setDeployed] = useState(false)

    const [config, setConfig] = useState([
        {
            name: "name",
            description: "Unique identifier for the API deployment. This name is used to distinguish between different deployments.",
            default: model.apiClass.replace("API", "Bulk"),
        },
        {
            name: "input_s3_folder",
            description: "",
            default: s3BucketUrl,
        },
        {
            name: "output_s3_folder",
            description: "",
            default: s3BucketUrl,
        },
        {
            name: "pod_size",
            description: "Defines the size of the pod, dictating its CPU, memory, and GPU resources. Choose a size based on the expected workload.",
            default: "m",
            options: [
                { label: " 🟢⚫⚫⚫ S - 0.25 VCPU, 1 GB RAM, 0.5GB GPU", value: "s" },
                { label: " 🟢🟢⚫⚫ M - 0.5 VCPU, 2 GB RAM, 1GB GPU", value: "m" },
                { label: " 🟢🟢🟢⚫ L - 1 VCPU, 4 GB RAM, 2GB GPU", value: "l" },
                { label: " 🟢🟢🟢🟢 XL - 2 VCPU, 8 GB RAM, 4GB GPU", value: "xl" },
                { label: " 🔥🟢🟢🟢 2XL - 4 VCPU, 16 GB RAM, 8GB GPU", value: "2xl" },
                { label: " 🔥🔥🟢🟢 4XL - 8 VCPU, 32 GB RAM, 16GB GPU", value: "4xl" },
                { label: " 🔥🔥🔥🟢 8XL - 16 VCPU, 64 GB RAM, 32GB GPU", value: "8xl" },
                { label: " 🔥🔥🔥🔥 16XL - 32 VCPU, 128 GB RAM, 64GB GPU", value: "16xl" },
            ],
        },
        {
            name: "cloud",
            description: "Selects the cloud to be deployed in. Select one according to your preference, usecase and cost requirements.",
            default: "azure-central-india",
            options: [
                { label: "E2E Networks - Delhi-NCR", value: "e2e-delhi" },
                { label: "Amazon AWS - ap-south-1 (Mumbai)", value: "AWS-ap-south-1" },
                { label: "Microsoft Azure - Central India (Pune)", value: "azure-central-india" },
                { label: "E2E Networks - Mumbai (Coming Soon)", value: "e2e-delhi" },
                { label: "Amazon AWS - us-east-1 (N. Virginia) (Coming Soon)", value: "AWS-us-east-1" },
                { label: "Microsoft Azure - Central USA (Coming Soon)", value: "azure-central-us" },
                { label: "Google Cloud - asia-south1 (Coming Soon)", value: "gcp-asia-south1" },
                { label: "Google Cloud - northamerica-east1 (Coming Soon)", value: "gcp-us-east1" },
            ],
        },
    ])

    const [modelSettings, setModelSettings] = useState(buildInitialState(model.bulkDeploy))

    const handleChange = (name: string, value: any) => {
        setConfig(prevState => prevState.map(configItem => (configItem.name === name ? { ...configItem, default: value } : configItem)))
    }

    // Initialize S3 bucket URL on component mount
    React.useEffect(() => {
        setBackgroundImage(`../../vector-autumn-foliage-banner/${getRandomImage()}`)
    }, [])

    AWS.config.update({
        region: "ap-south-1",
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        // credentials: new AWS.CognitoIdentityCredentials({
        //     IdentityPoolId: 'ap-south-1_m1rTttoqg',
        // }),
    })

    const s3 = new AWS.S3({
        apiVersion: "2006-03-01",
        params: { Bucket: "geniusrise-prod-input" },
    })

    const { setSupportContent } = useContext(SupportContentContext)

    useEffect(() => {
        setSupportContent({
            heading: "Deploy a bulk job",
            content:
                "This page allows you to deploy this machine learning model as a bulk job. Select from a range of pod sizes to optimize performance and resource utilization. Once deployed, you can view your job on the dashboard. Additionally, you can access and modify advanced settings like model parameters through the 'Configure Settings' option. The most optimum values are already pre-filled out. Please follow the instructions below to structure your input data.",
            examplesTitle: "Data Format",
            examples: [
                "For CSV, TSV, XLS, XLSX each file should contain the following columns: " + model.inputs.map(i => i.name).join(", "),
                "For JSONL each line should contain a JSON with fields: " + model.inputs.map(i => i.name).join(", "),
                "For JSON, YAML each file should contain these fields: " + model.inputs.map(i => i.name).join(", "),
                "For huggingface, parquet, sqlite etc, the dataset should contain these fields: " + model.inputs.map(i => i.name).join(", "),
                "You may upload multiple files",
                "You may upload directories with arbitrary nesting",
                "You may also use the generated S3 link to upload files via an external system like backend or spark",
            ],
            usecasesTitle: "Sizes:",
            useCases: [
                "s: 0.25 VCPU, 1 GB RAM, 0.5GB GPU",
                "m: 0.5 VCPU, 2 GB RAM, 1GB GPU",
                "l: 1 VCPU, 4 GB RAM, 2GB GPU",
                "xl: 2 VCPU, 8 GB RAM, 4GB GPU",
                "2xl: 4 VCPU, 16 GB RAM, 8GB GPU",
                "4xl: 8 VCPU, 32 GB RAM, 16GB GPU",
                "8xl: 16 VCPU, 64 GB RAM, 32GB GPU",
                "16xl: 32 VCPU, 128 GB RAM, 64GB GPU",
            ],
        })
    }, [])

    const uploadFilesToS3 = async () => {
        if (files.length === 0) {
            return
        }

        setProgressBarVisible(true)
        let totalUploaded = 0

        try {
            const uploadPromises = files.map(file => {
                const uploadParams = {
                    Bucket: "geniusrise-prod-input",
                    Key: `${s3BucketUrl}${file.name}`,
                    Body: file,
                }

                return s3
                    .upload(uploadParams)
                    .on("httpUploadProgress", evt => {
                        // Update progress
                        totalUploaded += evt.loaded
                        const progressPercentage = (totalUploaded / files.reduce((acc, file) => acc + file.size, 0)) * 100
                        setProgress(Math.min(100, progressPercentage))
                    })
                    .promise()
            })

            await Promise.all(uploadPromises)
            console.log("Files uploaded successfully.")
            handleLaunch()
        } catch (error) {
            console.error("Error uploading files: ", error)
        } finally {
        }
    }

    // Handle file drop
    const onDrop = useCallback((acceptedFiles: File[]) => {
        setFiles(acceptedFiles)
    }, [])

    // Set up the dropzone for drag and drop file upload
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "application/json": [".jsonl", ".json"], // JSON and JSON Lines format
            "text/csv": [".csv"], // CSV files
            "application/parquet": [".parquet"], // Parquet files
            "application/xml": [".xml"], // XML files
            "application/x-yaml": [".yaml"], // YAML files
            "text/tab-separated-values": [".tsv"], // TSV files
            "application/vnd.ms-excel": [".xls"], // Excel files
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"], // Excel files
            "application/octet-stream": [".sqlite", ".feather"], // Binary file types
            "image/jpeg": [".jpeg", ".jpg"], // JPEG images
            "image/png": [".png"], // PNG images
        },
    })

    const handleLaunch = () => {
        setProgressBarVisible(true)
        setProgress(0)

        const deploymentConfig = config.reduce((acc, item) => {
            // @ts-ignore
            acc[item.name] = item.default
            return acc
        }, {})

        createJob({
            task: {
                name: ("geniusbulk--" + generateName() + "--" + model.name.toLowerCase().replaceAll(" ", "-")).replaceAll(".", "-").substring(0, 60),
                deployment_config: {
                    ...deploymentConfig,
                },
                method: model.bulkMethod,
                method_args: {
                    ...modelSettings,
                    model_name: customModel,
                    notification_email: customNotification,
                },
            },
        })

        // Progress bar logic
        const interval = setInterval(() => {
            setProgress(oldProgress => {
                if (oldProgress === 100) {
                    setDeployed(true)
                    clearInterval(interval)
                    return 100
                }
                return Math.min(oldProgress + 1, 100)
            })
        }, 300) // 1200 ms interval for 2 minutes duration
    }

    return (
        <>
            <div className={styles.container} hidden={settingsVisible}>
                <Grid columns={2}>
                    <Cell>
                        <Content className={styles.contentHeading}>
                            <h2>Bulk inference - {model.name}</h2>
                            Upload data to run a inference using a model as a bulk job. Each file should contain the following fields:
                            <ol>
                                {model.inputs.map(i => (
                                    <li>
                                        <p>
                                            {i.name} (type: {i.type})
                                        </p>
                                    </li>
                                ))}
                            </ol>
                        </Content>
                    </Cell>
                </Grid>
                <Grid columns={2} className={styles.form}>
                    {isModelCustom ? (
                        <Cell key="customModel" className={styles.formElement} center>
                            <label>
                                Custom Model Name
                                <div>
                                    Input the name of the desired model. This name corresponds to the huggingface format:{" "}
                                    <SyntaxHighlighter style={shadesOfPurple}>repository_name/model_name:optional_model_tag</SyntaxHighlighter>
                                </div>
                                {
                                    <input
                                        type={"text"}
                                        className={styles.textInput}
                                        value={customModel}
                                        onChange={e => setCustomModel(e.target.value)}
                                    />
                                }
                            </label>
                        </Cell>
                    ) : (
                        <></>
                    )}
                    {config.map(({ name, description, default: defaultValue, options }) =>
                        name === "name" || name.includes("s3") ? (
                            <></>
                        ) : (
                            <Cell key={name} className={styles.formElement} center>
                                <label>
                                    {toTitleCase(name)}
                                    <div>{description}</div>
                                    {options ? (
                                        <select
                                            className={styles.selectInput}
                                            value={defaultValue}
                                            onChange={e => handleChange(name, e.target.value)}
                                        >
                                            {
                                                // @ts-ignore
                                                options.map((option: any) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    ) : (
                                        <input
                                            type="text"
                                            className={styles.textInput}
                                            value={defaultValue}
                                            onChange={e => handleChange(name, e.target.value)}
                                        />
                                    )}
                                </label>
                            </Cell>
                        )
                    )}
                    <Cell key="customNotification" className={styles.formElement} center>
                        <label>
                            Notification Email
                            <div>Input the email id to be notified once the fine-tuning is done:</div>
                            {
                                <input
                                    type={"text"}
                                    className={styles.textInput}
                                    value={customNotification}
                                    onChange={e => setCustomNotification(e.target.value)}
                                />
                            }
                        </label>
                    </Cell>
                </Grid>
                <Content className={styles.contentHeading}>
                    <h2>Upload Files</h2>
                    <p>Supported formats: {model.bulk_formats.join(", ")}</p>
                </Content>
                <div {...getRootProps()} className={styles.filesDrop}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <p>Drop the files here ...</p>
                    ) : (
                        <button>{files.length === 0 ? "Click to select files or folders or drag them here" : files.map(f => <p>{f.name}</p>)}</button>
                    )}
                </div>
                <Content className={styles.contentHeading}>
                    <h2>OR</h2>
                    <h2>Upload files in S3</h2>
                    Alternatively, you can upload files directly to the following S3 bucket:
                </Content>
                <Grid columns={20} className={styles.s3Location}>
                    <Cell width={19} center middle>
                        <p>{"s3://geniusrise-prod-input/" + s3BucketUrl}</p>
                    </Cell>
                    <Cell width={1} center middle>
                        <span>📋</span>
                    </Cell>
                </Grid>
                <div className={styles.s3Code}>
                    <SyntaxHighlighter language="bash" style={shadesOfPurple} wrapLines={true} showLineNumbers={true}>
                        {`aws s3 cp \\\n  --recursive\\\n  ./<YOUR_DATA>\\\n  s3://geniusrise-prod-input/${s3BucketUrl}`}
                    </SyntaxHighlighter>
                </div>
                <Grid columns={2} className={styles.action}>
                    <Cell>
                        <Button
                            onClick={(e: any) => {
                                setSettingsVisibile(!settingsVisible)
                            }}
                        >
                            Configure Settings
                        </Button>
                    </Cell>
                    <Cell>
                        <Button onClick={() => uploadFilesToS3()} disabled={progressBarVisible}>
                            Submit Job
                        </Button>
                    </Cell>
                    <Cell width={2}>
                        {progressBarVisible && (
                            <div className={styles.progressBar}>
                                <div className={styles.progress} style={{ width: `${progress}%` }}></div>
                            </div>
                        )}
                    </Cell>
                    <Cell width={2} className={styles.curl}>
                        <Content hidden={!deployed}>
                            <h3>🎊 Your bulk job is deployed!</h3>
                            {modelSettings.notification_email ? `We will send you an email notification at ${modelSettings.notification_email}` : ""}
                        </Content>
                    </Cell>
                </Grid>
            </div>
            <Settings
                config={Object.entries(model.bulkDeploy).reduce((mem, [key, value]) => {
                    if (!["notification_email", "model_class", "tokenizer_class"].includes(value.name)) {
                        // @ts-ignore
                        mem[key] = value
                        return mem
                    } else return mem
                }, {})}
                callback={x => {
                    setModelSettings({
                        ...modelSettings,
                        ...x,
                    })
                    setSettingsVisibile(!settingsVisible)
                }}
                visible={settingsVisible}
            ></Settings>
        </>
    )
}

export default withAuthenticator(Bulk)
